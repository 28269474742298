import React from "react";
import Layout from "../../components/Layout";
import { graphql, Link } from "gatsby";
import { Divider } from "../../components/Divider";

export default function Index({ data, location }) {
  const components = data.allContentfulComponent.nodes;

  return (
    <Layout
      location={location}
      breadcrumb1="Home"
      breadcrumb2="Components"
      title="Components"
      description="These are the building blocks of the Nielsen Design System. You can use these to help craft a new application or help modify an existing one. Please note that these are constantly being improved and added to, so check back later if there's something you don't see!"
    >
      <div className="styled-grid">
        <div className="row">
          {components.map((component) => (
            <React.Fragment>
              {component.status !== "In Progress" && (
                <div className="col-md-4 my-space-300">
                  <Link
                    to={
                      "/components/" +
                      component.name.toLowerCase().replace(/\s/g, "-") +
                      "/"
                    }
                    activeClassName="active"
                    key={component.id}
                    //style={{ minWidth: "256px" }}
                  >
                    <div className="component-card">
                      <div className="row mt-space-500 mx-space-300 mb-space-400">
                        <div className="gds-size-600-semibold col-12">
                          {component.name}
                        </div>
                      </div>
                      <Divider />
                      {component.snapshot !== null && (
                        <div className="d-flex justify-content-center img-background">
                          <img
                            src={component.snapshot.file.url}
                            style={{ width: "256px", height: "96px" }}
                            alt={component.snapshot.file.fileName}
                          />
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Layout>
  );
}

//export components list query
export const query = graphql`
  query ComponentList {
    allContentfulComponent(sort: { fields: name, order: ASC }) {
      nodes {
        status
        id
        name
        snapshot {
          file {
            url
            fileName
          }
        }
      }
    }
  }
`;
